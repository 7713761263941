import Vue from 'vue'
import Router from 'vue-router'
import $vuex from '@/store'
import login from './login'
import menu from './menu'
import permissions from './permissions'
import roles from './roles'
import setting from './setting'
import category from './category'
import sysLog from './sys_log'
import board from './board'
import profile from './profile'




// 解决vue报错vue-router.esm.js
const routerPush = Router.prototype.push
  Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}

Vue.use(Router)

// 路由详情
const routes = [
  {
    path: '/',
    component: () => import('@/layouts/BasicLayout'),
    meta: {title:'首页'},
    children: [
      menu, // 菜单
      permissions, // 权限
      roles, // 角色
      setting, // 基础设置
      category, // 分类管理
      sysLog, // 系统日志
      board,
      profile,
      {
        path: 'exception/404',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/exception/404')
      },
      {
        path: 'manager',
        component: () => import(/* webpackChunkName: "user-list" */ '@/pages/user/UserList'),
        meta: {title:'用户管理'}
      },
      {
        path: 'supplier',
        component: () => import(/* webpackChunkName: "roles-list" */ '@/pages/supplier/RolesList'),
        meta: {title:'商家管理'},
        children: [
        {
          path: ':role_id/manage_members',
          component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/supplier/ManageMembers'),
          meta: {title:'成员管理'},
        }
        ]
      },
      {
        path: 'article',
        component: () => import(/* webpackChunkName: "article-list" */ '@/pages/article/ArticleList'),
        meta: {title:'新闻管理'},
        children: [
          {
            path: 'create',
            component: () => import(/* webpackChunkName: "article-create" */ '@/pages/article/CreateEdit'),
            meta: {title:'添加新闻'},
          },
          {
            path: ':id/edit',
            component: () => import(/* webpackChunkName: "article-edit" */ '@/pages/article/CreateEdit'),
            meta: {title:'编辑新闻'},
          }
        ]
      },
      {
        path: 'blog',
        component: () => import(/* webpackChunkName: "article-list" */ '@/pages/blog/ArticleList'),
        meta: {title:'博客管理'},
        children: [
          {
            path: 'create',
            component: () => import(/* webpackChunkName: "article-create" */ '@/pages/blog/CreateEdit'),
            meta: {title:'添加博客'},
          },

        ]
      },
      {
        path: 'craft',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/craft/CraftList'),
        meta: {title:'工艺管理'},
        children: [
          {
            path: ':id/craft_category',
            component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/craft/CraftCategoryList'),
            meta: {title:'工艺分类'},
            children: [
              {
                path: ':id/craft_category_material',
                component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/craft/MaterialList'),
                meta: {title:'工艺材料'},
                children: [
                  {
                    path: ':id/material_color',
                    component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/craft/MaterialColourList'),
                    meta: {title:'材料颜色'},
                  },
                  {
                    path: ':id/material_duration',
                    component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/craft/MaterialDurationList'),
                    meta: {title:'材料工期'},
                  },
                  {
                    path: ':id/material_follow_craft',
                    component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/craft/MaterialFollowCraftList'),
                    meta: {title:'材料后期工艺'},
                  },
                ]
              },
              
            ]
          },
         
        ]
      },
      {
        path: 'material',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/materials/MaterialsList'),
        meta: {title:'材料管理'}
      },
      {
        path: 'colour',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/colour/ColourList'),
        meta: {title:'材料管理'}
      },
      {
        path: 'follow_craft',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/followcraft/FollowCraftList'),
        meta: {title:'后期工艺管理'},
        children: [
          {
            path: ':id/follow_craft_phase_price',
            component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/followcraft/FollowCraftPhasePriceList'),
            meta: {title:'后期工艺价格'},
          },
        ]
        
      },
      {
        path: 'supplier_product',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/supplierproduct/SupplierProductList'),
        meta: {title:'供应管理'}
      },
      {
        path: 'department',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/department/DepartmentList'),
        meta: {title:'部门列表'}
      },
      {
        path: 'department',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/department/DepartmentList'),
        meta: {title:'部门列表'}
      },
      {
        path: 'customer',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/customer/CustomerList'),
        meta: {title:'客户信息'},
        children: [
          {
            path: ':id/customer_address',
            component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/customer/CustomerAddressList'),
            meta: {title:'客户地址'},
          },
          {
            path: ':id/customer_file',
            component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/customer/CustomerOrderFileList'),
            meta: {title:'客户文件'},
          }
        ]
      },
      {
        path: 'company',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/company/CompanyList'),
        meta: {title:'公司信息'},
        children: [
          {
            path: ':id/manage_members',
            component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/company/ManageMembers'),
            meta: {title:'成员管理'},
          }
          ]
      },
      {
        path: 'sales_customer',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/salescustomer/SalesCustomerList'),
        meta: {title:'销售&客户'}
      },
      {
        path: 'follow_record',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/department/DepartmentList'),
        meta: {title:'跟进记录'}
      },
      {
        path: 'channel',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/channel/ChannelList'),
        meta: {title:'渠道管理'}
      },
      {
        path: 'customer_order',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/customerorder/CustomerOrderList'),
        meta: {title:'订单'},
        children: [
          {
            path: ':id/customer_order_item',
            component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/customerorder/CustomerOrderItemList'),
            meta: {title:'订单明细'},
            children: [
              {
                path: ':order_no/:file_id/customer_order_item_craft_detail',
                component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/customerorder/CustomerOrderItemCraftDetailList'),
                meta: {title:'订单工艺明细'},
              }
            ]
          }
        ]
      },
      {
        path: 'customer_order_file',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/customerorderfile/CustomerOrderFileList'),
        meta: {title:'订单文件'}
      },
      {
        path: 'coupon',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/coupon/CouponList'),
        meta: {title:'优惠券'}
      },
      {
        path: 'arrive_address',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/arriveaddress/ArriveAddressList'),
        meta: {title:'可达地区'}
      },
      {
        path: 'express_cost',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/arriveaddresscost/ArriveAddressCostList'),
        meta: {title:'费用管理'}
      },
      {
        path: 'api',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/api/ApiGroupList'),
        meta: {title:'Api组'},
        children: [
          {
            path: ':entry_id/api_item',
            component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/api/ManageApi'),
            meta: {title:'接口管理'},
            children: [
              {
                path: ':item_id/args_template',
                component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/api/api_item/ArgsTemplateList'),
                meta: {title:'接口管理'},
              },
              {
                path: ':item_id/verify',
                component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/api/api_item/VerifyList'),
                meta: {title:'接口校验'},
              },
              {
                path: ':item_id/config',
                component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/api/api_item/ConfigList'),
                meta: {title:'接口模板'},
              },
              {
                path: ':item_id/func',
                component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/api/api_item/FuncList'),
                meta: {title:'接口模板'},
              },
              {
                path: ':item_id/result_template',
                component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/api/api_item/ResultTemplateList'),
                meta: {title:'结果模板'},
              },
              {
                path: ':item_id/err_message',
                component: () => import(/* webpackChunkName: "manage_members" */ '@/pages/api/api_item/ErrMessageList'),
                meta: {title:'错误信息'},
              }
            ]  
          }
        ]
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/PassportLayout'),
    children: [
      login, // 登录
    ]
  },
  {
    path:"*",
    redirect:'/exception/404',
    // component: () => import('@/pages/exception/404')
  }
]

// 实例化
const router = new Router({
  base : process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL : '/',
  mode : 'history',
  routes
})

// 路由钩子
router.beforeEach((to, from, next) => {
  ifLogin(to, next)
  handelSiderMenu(to)
  next()
});

/**
 * @description 处理左侧菜单的选中
 * @author 春天在哪里
 * @date 2023-03-13
 * @param {*} to 当前路由
 */
let handelSiderMenu =  (to) => {
  $vuex.commit('handleRoutePath', to.path)
}

/**
 * @description 处理左侧菜单的选中
 * @author 春天在哪里
 * @date 2023-03-13
 * @param {*} to 当前路由
 * @param {*} next 回调函数
 */
let ifLogin = (to, next) => {
  let userInfo = JSON.parse(localStorage.getItem('user_info'))
  // 判断是否是登录页
  let idx = to.path.indexOf('login') != -1
   // 没有userInfo 且 不是login 页 => 登录页
  if (!userInfo && !idx) {
    next('/login')
  }
  // 有userinfo 且 是login 页 => 首页
  if (idx && userInfo) {
    next('/')
  }
}

export default router
