import * as request from '@/utils/request'

export default {
  /**
   * @description 获取用户菜单list，用于左侧导航
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
  getUserMenuList() {
    return request.post('/server/menu/list')
  },
  /**
   * @description 获取用户菜单list，用于权限管理菜单
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
   getAllMenuNodes(data) {
    return request.post('/server/withitem/menu_nodes/node',data)
  },
  /**
   * @description 获取用户菜单list，用于左侧导航
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
  getUserMenuNodes(data) {
    return request.post('/server/user/menu/nodes',data)
  },
   /**
   * @description 获取用户权限list，用于页面按钮相关的控制
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
    getPermissionsNodes(data) {
      return request.post('/server/withitem/permissions_nodes/node',data)
    },
  /**
   * getOssToken
   * @description 获取上传凭证
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns
   */
  getOssToken() {
    return request.post('/server/upload/image')
  },
  /**
   * editorUploadFile
   * @description 富文本上传文件
   * @author 春天在哪里
   * @date 2019-01-31
   * @returns
   */
  editorUploadFile(data, ossUrl) {
    if(ossUrl){
      return request.post(ossUrl, data)
    }else{
      return request.post('/upload/editor', data)
    }
  },
  /**
   * @description 获取阿里云vod视频上传令牌信息
   * @author 春天在哪里
   * @date 2019-02-25
   * @returns 
   */
  getVodToken(data) {
    return request.post('/upload/get_aliyun_vod_token',data)
  }
}