import passport from './passport'
import common from './common'
import statistics from './statistics'
import menu from './menu'
import permissions from './permissions'
import roles from './roles'
import user from './user'
import category from './category'
import sysLog from './sys_log'
import web from './web'


export default {
  // 通行证
  ...passport,
  // 通用
  ...common,
  // 统计数据
  ...statistics,
  // 菜单
  ...menu,
  // 权限
  ...permissions,
  // 角色
  ...roles,
  // 用户
  ...user,
  // 分类管理
  ...category,
  // 系统日志管理
  ...sysLog,
  //门户管理
  ... web,

}
