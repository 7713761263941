import * as request from '@/utils/request'

export default {
 // 工艺
  /**
   * getCrafts
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCrafts(data) {
    return request.post('/server/general/craft/common/list', data)
  },
   /**
   * ModifyCraft
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyCraft(data) {
    return request.post('/server/general/craft/common/modify', data)
  },
  /**
   * saveCraft
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addCraft(data) {
    return request.post('/server/general/craft/common/add', data)
  },
  /**
   * getCraftInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCraft(id) {
    let data = {id:id}
    return request.post('/server/general/craft/common/detail', data)
  },
  /**
   * deleteCraftInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteCraft(id) {
    let data = {id:id}
    return request.post('/server/general/craft/common/delete', data)
  },

 



// 材料
  /**
   * getMaterial
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getMaterials(data) {
    return request.post('/server/general/material/common/list', data)
  },
   /**
   * ModifyMaterial
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyMaterial(data) {
    return request.post('/server/general/material/common/modify', data)
  },
  /**
   * saveMaterial
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addMaterial(data) {
    return request.post('/server/general/material/common/add', data)
  },
  /**
   * getMaterialInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getMaterial(id) {
    let data = {id:id}
    return request.post('/server/general/material/common/detail', data)
  },
  /**
   * deleteMaterialInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteMaterial(id) {
    let data = {id:id}
    return request.post('/server/general/material/common/delete', data)
  },



// 颜色
  /**
   * getColour
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getColours(data) {
    return request.post('/server/general/colour/common/list', data)
  },
   /**
   * ModifyColour
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyColour(data) {
    return request.post('/server/general/colour/common/modify', data)
  },
  /**
   * saveColour
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addColour(data) {
    return request.post('/server/general/colour/common/add', data)
  },
  /**
   * getColourInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getColour(id) {
    let data = {id:id}
    return request.post('/server/general/colour/common/detail', data)
  },
  /**
   * deleteColourInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteColour(id) {
    let data = {id:id}
    return request.post('/server/general/colour/common/delete', data)
  },


// 后期工艺
  /**
   * getFollowCraft
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getFollowCrafts(data) {
    return request.post('/server/general/follow_craft/common/list', data)
  },
   /**
   * ModifyFollowCraft
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyFollowCraft(data) {
    return request.post('/server/general/follow_craft/common/modify', data)
  },
  /**
   * saveFollowCraft
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addFollowCraft(data) {
    return request.post('/server/general/follow_craft/common/add', data)
  },
  /**
   * getFollowCraftInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getFollowCraft(id) {
    let data = {id:id}
    return request.post('/server/general/follow_craft/common/detail', data)
  },
  /**
   * deleteFollowCraftInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteFollowCraft(id) {
    let data = {id:id}
    return request.post('/server/general/follow_craft/common/delete', data)
  },
  // 供应产品
  /**
   * getSupplierProduct
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getSupplierProducts(data) {
    return request.post('/server/withitem/supplier_product_items/list', data)
  },
   /**
   * ModifySupplierProduct
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifySupplierProduct(data) {
    return request.post('/server/supplier/product/modify', data)
  },
  /**
   * saveSupplierProduct
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addSupplierProduct(data) {
    return request.post('/server/supplier/product/add', data)
  },
  /**
   * getSupplierProductInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getSupplierProduct(id) {
    let data = {id:id}
    return request.post('/server/withitem/supplier_product_items/detail', data)
  },
  /**
   * deleteSupplierProductInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteSupplierProduct(id) {
    let data = {id:id}
    return request.post('/server/supplier/product/delete', data)
  },
  /**
   * @description 获取站点配置信息
   * @author 春天在哪里
   * @date 2023-03-18
   * @returns 
   */
  getSettingSiteInfo(data) {
    return request.post('/server/general/setting/common/list',data)
  },
  /**
   * @description 保存站点配置信息
   * @author 春天在哪里
   * @date 2023-03-18
   * @param {*} data
   * @returns 
   */
  addSettingSite(data) {
    return request.post('/server/general/setting/common/add', data)
  },
  /**
   * @description 保存站点配置信息
   * @author 春天在哪里
   * @date 2023-03-18
   * @param {*} data
   * @returns 
   */
  modifySettingSite(data) {
    return request.post('/server/general/setting/common/modify', data)
  },
/**
 * @description 获取新闻列表
 * @author 春天在哪里
 * @date 2023-03-04
 * @param {*} data
 * @returns 
 */
  getArticleList(data) {
    return request.post('/server/general/article/common/list', data)
  },
  /**
   * @description 获取新闻分类列表
   * @author 春天在哪里
   * @date 2023-03-04
   * @returns 
   */
  getSpecialCategoryList(data) {
    return request.post('/server/category/nodes',data)
  },
  /**
   * @description 新闻保存
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns 
   */
  addArticle(data) {
    return request.post('/server/general/article/common/add', data)
  },
  /**
   * @description 新闻保存
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns 
   */
  modifyArticle(data) {
    return request.post('/server/general/article/common/modify', data)
  },
  /**
   * @description 获取新闻详情
   * @author 春天在哪里
   * @date 2019-02-11
   * @param {*} id
   * @returns 
   */
  getArticleInfo(id) {
    let data = {id:id}
    return request.post('/server/general/article/common/detail', data)
  },
  /**
   * @description 根据id删除单条信息
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  deleteArticle(id) {
    let data = {id:id}
    return request.post('/server/general/article/common/delete',data)
  },


  //部门
  /**
   * getDepartment
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getDepartments(data) {
    return request.post('/server/general/department/common/list', data)
  },
   /**
   * ModifyDepartment
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyDepartment(data) {
    return request.post('/server/general/department/common/modify', data)
  },
  /**
   * saveDepartment
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addDepartment(data) {
    return request.post('/server/general/department/common/add', data)
  },
  /**
   * getDepartmentInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getDepartment(id) {
    let data = {id:id}
    return request.post('/server/general/department/common/detail', data)
  },
  /**
   * deleteDepartmentInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteDepartment(id) {
    let data = {id:id}
    return request.post('/server/general/department/common/delete', data)
  },


   /**
   * @description 获取角色列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
   getCompanyList(data) {
    return request.post('/server/general/customer_group/common/list',data)
  },
  /**
   * @description 新增角色
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addCompany(data) {
    return request.post('/server/general/customer_group/common/add',data)
  },
  /**
   * @description 保存权限
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  modifyCompany(data) {
    return request.post('/server/general/customer_group/common/modify',data)
  },
  /**
   * @description 根据id获取单条信息，编辑使用
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCompanyInfo(id) {
    let data = {id:id}
    return request.post('/server/general/customer_group/common/detail',data)
  },
  /**
   * @description 根据id删除单条信息
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  deleteCompany(id) {
    let data = {id:id}
    return request.post('/server/general/customer_group/common/delete',data)
  },
  /**
   * @description 角色权限绑定，获取信息
   * @author 春天在哪里
   * @date 2019-01-26
   * @param {*} id 角色id
   * @returns 
   */
  //渠道
  /**
   * getChannel
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getChannels(data) {
    return request.post('/server/general/channel/common/list', data)
  },
   /**
   * ModifyChannel
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyChannel(data) {
    data.start_time = data.start_time.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ').substring(0,19)
    return request.post('/server/general/channel/common/modify', data)
  },
  /**
   * saveChannel
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addChannel(data) {
    return request.post('/server/general/channel/common/add', data)
  },
  /**
   * getChannelInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getChannel(id) {
    let data = {id:id}
    return request.post('/server/general/channel/common/detail', data)
  },
  /**
   * deleteChannelInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteChannel(id) {
    let data = {id:id}
    return request.post('/server/general/channel/common/delete', data)
  },


  //组客户
  /**
   * getCustomerGroupUser
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomerGroupUsers(data) {
    return request.post('/server/withitem/customer_group_user_item/list', data)
  },
   /**
   * ModifyCustomerGroupUser
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyCustomerGroupUser(data) {
    return request.post('/server/general/customer_group_user/common/modify', data)
  },
  /**
   * addCustomerGroupUser
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addCustomerGroupUser(data) {
    return request.post('/server/general/customer_group_user/common/add', data)
  },
  /**
   * getCustomerGroupUserInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomerGroupUser(id) {
    let data = {id:id}
    return request.post('/server/general/customer_group_user/common/detail', data)
  },
  /**
   * deleteCustomerGroupUserInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteCustomerGroupUser(id) {
    let data = {id:id}
    return request.post('/server/general/customer_group_user/common/delete', data)
  },





  //优惠券
  getCoupons(data) {
    return request.post('/server/general/coupon/common/list', data)
  },
   /**
   * ModifyCoupon
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyCoupon(data) {
    return request.post('/server/general/coupon/common/modify', data)
  },
  /**
   * saveCoupon
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addCoupon(data) {
    return request.post('/server/general/coupon/common/add', data)
  },
  /**
   * getCouponInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCoupon(id) {
    let data = {id:id}
    return request.post('/server/general/coupon/common/detail', data)
  },
  /**
   * deleteCouponInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteCoupon(id) {
    let data = {id:id}
    return request.post('/server/general/coupon/common/delete', data)
  },




  //客户
  /**
   * getCustomer
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomers(data) {
    return request.post('/server/withitem/user_customer_group_items/list', data)
  },
   /**
   * getCustomer
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomerList(data) {
    return request.post('/server/general/customer/common/list', data)
  },
   /**
   * ModifyCustomer
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyCustomer(data) {
    return request.post('/server/general/customer/common/modify', data)
  },
  /**
   * saveCustomer
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addCustomer(data) {
    return request.post('/server/general/customer/common/add', data)
  },
  /**
   * getCustomerInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomer(id) {
    let data = {id:id}
    return request.post('/server/general/customer/common/detail', data)
  },
  /**
   * deleteCustomerInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteCustomer(id) {
    let data = {id:id}
    return request.post('/server/general/customer/common/delete', data)
  },




  
  //销售&&客户
  /**
   * getUserCustomer
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getUserCustomers(data) {
    return request.post('/server/withitem/user_customer_items/list', data)
  },
    /**
   * @description 获取会员用户列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
    getUserList(data) {
      return request.post('/server/general/user/common/list',data)
    },
   /**
   * ModifyUserCustomer
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyUserCustomer(data) {
    return request.post('/server/general/user_customer/common/modify', data)
  },
  /**
   * saveUserCustomer
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addUserCustomer(data) {
    return request.post('/server/user_customer/add', data)
  },
  /**
   * getCustomerInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getUserCustomer(id) {
    let data = {id:id}
    return request.post('/server/general/user_customer/common/detail', data)
  },
  /**
   * deleteUserCustomerInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteUserCustomer(id) {
    let data = {id:id}
    return request.post('/server/general/user_customer/common/delete', data)
  },




  //客户文件
  /**
   * getUserCustomer
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomerOrderFiles(data) {
    return request.post('/server/withitem/customer_file_items/list', data)
  },
  /**
   * getCustomerOrderFile
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomerOrderFileList(data) {
    return request.post('/server/general/customer_file/common/list', data)
  },
   /**
   * ModifyCustomerOrderFile
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyCustomerOrderFile(data) {
    return request.post('/server/general/customer_file/common/modify', data)
  },
  /**
   * saveCustomerOrderFile
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addCustomerOrderFile(data) {
    return request.post('/server/general/customer_file/common/add', data)
  },
  /**
   * getCustomerOrderFileInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomerOrderFile(id) {
    let data = {id:id}
    return request.post('/server/general/customer_file/common/detail', data)
  },
  /**
   * deleteCustomerOrderFile
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteCustomerOrderFile(id) {
    let data = {id:id}
    return request.post('/server/general/customer_file/common/delete', data)
  },









   /**
   * getArriveAddress
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   getArriveAddressList(data) {
    return request.post('/server/general/arrive_address/common/list', data)
  },
   /**
   * ModifyArriveAddress
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyArriveAddress(data) {
    return request.post('/server/general/arrive_address/common/modify', data)
  },
  /**
   * saveArriveAddress
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addArriveAddress(data) {
    return request.post('/server/general/arrive_address/common/add', data)
  },
  /**
   * getArriveAddressInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getArriveAddress(id) {
    let data = {id:id}
    return request.post('/server/general/arrive_address/common/detail', data)
  },
  /**
   * deleteArriveAddressInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteArriveAddress(id) {
    let data = {id:id}
    return request.post('/server/general/arrive_address/common/delete', data)
  },




    //客户
  /**
   * getCustomer
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getArriveAddressCosts(data) {
    return request.post('/server/withitem/arrive_address_cost_items/list', data)
  },
  /**
   * getArriveAddressCost
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getArriveAddressCostList(data) {
    return request.post('/server/general/arrive_address_cost/common/list', data)
  },
   /**
   * ModifyArriveAddressCost
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyArriveAddressCost(data) {
    return request.post('/server/general/arrive_address_cost/common/modify', data)
  },
  /**
   * saveArriveAddressCost
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addArriveAddressCost(data) {
    return request.post('/server/general/arrive_address_cost/common/add', data)
  },
  /**
   * getArriveAddressCostInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getArriveAddressCost(id) {
    let data = {id:id}
    return request.post('/server/general/arrive_address_cost/common/detail', data)
  },
  /**
   * deleteArriveAddressCostInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteArriveAddressCost(id) {
    let data = {id:id}
    return request.post('/server/general/arrive_address_cost/common/delete', data)
  },

     /**
   * getAppApiEntry
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
     getAppApiEntryList(data) {
      return request.post('/server/general/app_api_entry/common/list', data)
    },
     /**
     * ModifyAppApiEntry
     * @description 更新
     * @author 春天在哪里
     * @date 2023-03-13
     * @param {*} data
     * @returns
     */
     storeAppApiEntry(data) {
      if(data.id==0){
        return request.post('/server/general/app_api_entry/common/add', data)
      }
      return request.post('/server/general/app_api_entry/common/modify', data)
    },
    /**
     * getAppApiEntryInfo
     * @description 获取详情
     * @author 春天在哪里
     * @date 2023-03-13
     * @param {*} data
     * @returns
     */
    getAppApiEntry(id) {
      let data = {id:id}
      return request.post('/server/general/app_api_entry/common/detail', data)
    },
    /**
     * deleteAppApiEntryInfo
     * @description 删除详情
     * @author 春天在哪里
     * @date 2023-03-13
     * @param {*} id
     * @returns
     */
    deleteAppApiEntry(id) {
      let data = {id:id}
      return request.post('/server/general/app_api_entry/common/delete', data)
    },

         /**
   * getAppApiEntryItem
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
    getAppApiEntryItemList(data) {
      return request.post('/server/general/app_api_entry_item/common/list', data)
    },
      /**
     * ModifyAppApiEntryItem
     * @description 更新
     * @author 春天在哪里
     * @date 2023-03-13
     * @param {*} data
     * @returns
     */
      storeAppApiEntryItem(data) {
      if(data.id==0){
        return request.post('/server/general/app_api_entry_item/common/add', data)
      }
      return request.post('/server/general/app_api_entry_item/common/modify', data)
    },
    /**
     * getAppApiEntryItemInfo
     * @description 获取详情
     * @author 春天在哪里
     * @date 2023-03-13
     * @param {*} data
     * @returns
     */
    getAppApiEntryItem(id) {
      let data = {id:id}
      return request.post('/server/general/app_api_entry_item/common/detail', data)
    },
    /**
     * deleteAppApiEntryItemInfo
     * @description 删除详情
     * @author 春天在哪里
     * @date 2023-03-13
     * @param {*} id
     * @returns
     */
    deleteAppApiEntryItem(id) {
      let data = {id:id}
      return request.post('/server/general/app_api_entry_item/common/delete', data)
    },
        /**
     * getAppApiEntryItemArgsTemplate
     * @description 管理列表
     * @author 春天在哪里
     * @date 2023-03-13
     * @param {*} data
     * @returns
       */
      getAppApiEntryItemArgsTemplateList(data) {
        return request.post('/server/general/app_api_entry_item_args_templates/common/list', data)
      },
        /**
       * ModifyAppApiEntryItemArgsTemplate
       * @description 更新
       * @author 春天在哪里
       * @date 2023-03-13
       * @param {*} data
       * @returns
       */
        storeAppApiEntryItemArgsTemplate(data) {
        if(data.id==0){
          return request.post('/server/general/app_api_entry_item_args_templates/common/add', data)
        }
        return request.post('/server/general/app_api_entry_item_args_templates/common/modify', data)
      },
      /**
       * getAppApiEntryItemArgsTemplateInfo
       * @description 获取详情
       * @author 春天在哪里
       * @date 2023-03-13
       * @param {*} data
       * @returns
       */
      getAppApiEntryItemArgsTemplate(id) {
        let data = {id:id}
        return request.post('/server/general/app_api_entry_item_args_templates/common/detail', data)
      },
      /**
       * deleteAppApiEntryItemArgsTemplateInfo
       * @description 删除详情
       * @author 春天在哪里
       * @date 2023-03-13
       * @param {*} id
       * @returns
       */
      deleteAppApiEntryItemArgsTemplate(id) {
        let data = {id:id}
        return request.post('/server/general/app_api_entry_item_args_templates/common/delete', data)
      },
      /**
    * getAppApiEntryItemVerify
    * @description 管理列表
    * @author 春天在哪里
    * @date 2023-03-13
    * @param {*} data
    * @returns
      */
    getAppApiEntryItemVerifyList(data) {
      return request.post('/server/general/app_api_entry_item_verify/common/list', data)
    },
      /**
      * ModifyAppApiEntryItemVerify
      * @description 更新
      * @author 春天在哪里
      * @date 2023-03-13
      * @param {*} data
      * @returns
      */
    storeAppApiEntryItemVerify(data) {
      if(data.id==0){
        return request.post('/server/general/app_api_entry_item_verify/common/add', data)
      }
      return request.post('/server/general/app_api_entry_item_verify/common/modify', data)
    },
    /**
      * getAppApiEntryItemVerifyInfo
      * @description 获取详情
      * @author 春天在哪里
      * @date 2023-03-13
      * @param {*} data
      * @returns
      */
    getAppApiEntryItemVerify(id) {
      let data = {id:id}
      return request.post('/server/general/app_api_entry_item_verify/common/detail', data)
    },
    /**
      * deleteAppApiEntryItemVerifyInfo
      * @description 删除详情
      * @author 春天在哪里
      * @date 2023-03-13
      * @param {*} id
      * @returns
      */
    deleteAppApiEntryItemVerify(id) {
      let data = {id:id}
      return request.post('/server/general/app_api_entry_item_verify/common/delete', data)
    },
    /**
  * getAppApiEntryItemConfig
  * @description 管理列表
  * @author 春天在哪里
  * @date 2023-03-13
  * @param {*} data
  * @returns
    */
    getAppApiEntryItemConfigList(data) {
      return request.post('/server/general/app_api_entry_item_config/common/list', data)
    },
      /**
      * ModifyAppApiEntryItemConfig
      * @description 更新
      * @author 春天在哪里
      * @date 2023-03-13
      * @param {*} data
      * @returns
      */
    storeAppApiEntryItemConfig(data) {
      if(data.id==0){
        return request.post('/server/general/app_api_entry_item_config/common/add', data)
      }
      return request.post('/server/general/app_api_entry_item_config/common/modify', data)
    },
    /**
      * getAppApiEntryItemConfigInfo
      * @description 获取详情
      * @author 春天在哪里
      * @date 2023-03-13
      * @param {*} data
      * @returns
      */
    getAppApiEntryItemConfig(id) {
      let data = {id:id}
      return request.post('/server/general/app_api_entry_item_config/common/detail', data)
    },
    /**
      * deleteAppApiEntryItemConfigInfo
      * @description 删除详情
      * @author 春天在哪里
      * @date 2023-03-13
      * @param {*} id
      * @returns
      */
    deleteAppApiEntryItemConfig(id) {
      let data = {id:id}
      return request.post('/server/general/app_api_entry_item_config/common/delete', data)
    },
    /**
  * getAppApiEntryItemFunc
  * @description 管理列表
  * @author 春天在哪里
  * @date 2023-03-13
  * @param {*} data
  * @returns
    */
    getAppApiEntryItemFuncList(data) {
      return request.post('/server/general/app_api_entry_item_funcs/common/list', data)
    },
      /**
      * ModifyAppApiEntryItemFunc
      * @description 更新
      * @author 春天在哪里
      * @date 2023-03-13
      * @param {*} data
      * @returns
      */
    storeAppApiEntryItemFunc(data) {
      if(data.id==0){
        return request.post('/server/general/app_api_entry_item_funcs/common/add', data)
      }
      return request.post('/server/general/app_api_entry_item_funcs/common/modify', data)
    },
    /**
      * getAppApiEntryItemFuncInfo
      * @description 获取详情
      * @author 春天在哪里
      * @date 2023-03-13
      * @param {*} data
      * @returns
      */
    getAppApiEntryItemFunc(id) {
      let data = {id:id}
      return request.post('/server/general/app_api_entry_item_funcs/common/detail', data)
    },
    /**
      * deleteAppApiEntryItemFuncInfo
      * @description 删除详情
      * @author 春天在哪里
      * @date 2023-03-13
      * @param {*} id
      * @returns
      */
    deleteAppApiEntryItemFunc(id) {
      let data = {id:id}
      return request.post('/server/general/app_api_entry_item_funcs/common/delete', data)
    },
  /**
* getAppApiEntryItemErrMessage
* @description 管理列表
* @author 春天在哪里
* @date 2023-03-13
* @param {*} data
* @returns
*/
  getAppApiEntryItemErrMessageList(data) {
    return request.post('/server/general/app_api_err_message/common/list', data)
  },
    /**
    * ModifyAppApiEntryItemErrMessage
    * @description 更新
    * @author 春天在哪里
    * @date 2023-03-13
    * @param {*} data
    * @returns
    */
  storeAppApiEntryItemErrMessage(data) {
    if(data.id==0){
      return request.post('/server/general/app_api_err_message/common/add', data)
    }
    return request.post('/server/general/app_api_err_message/common/modify', data)
  },
  /**
    * getAppApiEntryItemErrMessageInfo
    * @description 获取详情
    * @author 春天在哪里
    * @date 2023-03-13
    * @param {*} data
    * @returns
    */
  getAppApiEntryItemErrMessage(id) {
    let data = {id:id}
    return request.post('/server/general/app_api_err_message/common/detail', data)
  },
  /**
    * deleteAppApiEntryItemErrMessageInfo
    * @description 删除详情
    * @author 春天在哪里
    * @date 2023-03-13
    * @param {*} id
    * @returns
    */
  deleteAppApiEntryItemErrMessage(id) {
    let data = {id:id}
    return request.post('/server/general/app_api_err_message/common/delete', data)
  },

  /**
* getAppApiEntryItemResultTemplate
* @description 管理列表
* @author 春天在哪里
* @date 2023-03-13
* @param {*} data
* @returns
  */
  getAppApiEntryItemResultTemplateList(data) {
    return request.post('/server/general/app_api_entry_item_result_templates/common/list', data)
  },
    /**
    * ModifyAppApiEntryItemResultTemplate
    * @description 更新
    * @author 春天在哪里
    * @date 2023-03-13
    * @param {*} data
    * @returns
    */
  storeAppApiEntryItemResultTemplate(data) {
    if(data.id==0){
      return request.post('/server/general/app_api_entry_item_result_templates/common/add', data)
    }
    return request.post('/server/general/app_api_entry_item_result_templates/common/modify', data)
  },
  /**
    * getAppApiEntryItemResultTemplateInfo
    * @description 获取详情
    * @author 春天在哪里
    * @date 2023-03-13
    * @param {*} data
    * @returns
    */
  getAppApiEntryItemResultTemplate(id) {
    let data = {id:id}
    return request.post('/server/general/app_api_entry_item_result_templates/common/detail', data)
  },
  /**
    * deleteAppApiEntryItemResultTemplateInfo
    * @description 删除详情
    * @author 春天在哪里
    * @date 2023-03-13
    * @param {*} id
    * @returns
    */
  deleteAppApiEntryItemResultTemplate(id) {
    let data = {id:id}
    return request.post('/server/general/app_api_entry_item_result_templates/common/delete', data)
  },

  //组客户
  /**
   * getCustomerAddress
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomerAddresss(data) {
    return request.post('/server/general/customer_address/common/list', data)
  },
   /**
   * ModifyCustomerAddress
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyCustomerAddress(data) {
    return request.post('/server/general/customer_address/common/modify', data)
  },
  /**
   * addCustomerAddress
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addCustomerAddress(data) {
    return request.post('/server/general/customer_address/common/add', data)
  },
  /**
   * getCustomerAddressInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomerAddress(id) {
    let data = {id:id}
    return request.post('/server/general/customer_address/common/detail', data)
  },
  /**
   * deleteCustomerAddressInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteCustomerAddress(id) {
    let data = {id:id}
    return request.post('/server/general/customer_address/common/delete', data)
  },


    //组客户
  /**
   * getCraftCategory
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCraftCategorys(data) {
    return request.post('/end/ola_backend/material_category/list', data)
  },
   /**
   * ModifyCraftCategory
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyCraftCategory(data) {
    return request.post('/end/ola_backend/material_category/modify', data)
  },
  /**
   * addCraftCategory
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addCraftCategory(data) {
    return request.post('/end/ola_backend/material_category/add', data)
  },
  /**
   * getCraftCategoryInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCraftCategory(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/material_category/detail', data)
  },
  /**
   * deleteCraftCategoryInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteCraftCategory(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/material_category/delete', data)
  },

   //组客户
  /**
   * getMaterialColour
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getMaterialColours(data) {
    return request.post('/end/ola_backend/material_colour/list', data)
  },
   /**
   * ModifyMaterialColour
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyMaterialColour(data) {
    return request.post('/end/ola_backend/material_colour/modify', data)
  },
  /**
   * addMaterialColour
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addMaterialColour(data) {
    return request.post('/end/ola_backend/material_colour/add', data)
  },
  /**
   * getMaterialColourInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getMaterialColour(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/material_colour/detail', data)
  },
  /**
   * deleteMaterialColourInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteMaterialColour(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/material_colour/delete', data)
  },

  
   //组客户
  /**
   * getMaterialDuration
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getMaterialDurations(data) {
    return request.post('/end/ola_backend/material_duration/list', data)
  },
   /**
   * ModifyMaterialDuration
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyMaterialDuration(data) {
    return request.post('/end/ola_backend/material_duration/modify', data)
  },
  /**
   * addMaterialDuration
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addMaterialDuration(data) {
    return request.post('/end/ola_backend/material_duration/add', data)
  },
  /**
   * getMaterialDurationInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getMaterialDuration(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/material_duration/detail', data)
  },
  /**
   * deleteMaterialDurationInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteMaterialDuration(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/material_duration/delete', data)
  },

  
   //组客户
  /**
   * getMaterialFollowCraft
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getMaterialFollowCrafts(data) {
    return request.post('/end/ola_backend/material_follow_craft/list', data)
  },
   /**
   * ModifyMaterialFollowCraft
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyMaterialFollowCraft(data) {
    return request.post('/end/ola_backend/material_follow_craft/modify', data)
  },
  /**
   * addMaterialFollowCraft
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addMaterialFollowCraft(data) {
    return request.post('/end/ola_backend/material_follow_craft/add', data)
  },
  /**
   * getMaterialFollowCraftInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getMaterialFollowCraft(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/material_follow_craft/detail', data)
  },
  /**
   * deleteMaterialFollowCraftInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteMaterialFollowCraft(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/material_follow_craft/delete', data)
  },

  
   //组客户
  /**
   * getFollowCraftPhasePrice
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getFollowCraftPhasePrices(data) {
    return request.post('/end/ola_backend/follow_craft_phase_price/list', data)
  },
   /**
   * ModifyFollowCraftPhasePrice
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyFollowCraftPhasePrice(data) {
    return request.post('/end/ola_backend/follow_craft_phase_price/modify', data)
  },
  /**
   * addFollowCraftPhasePrice
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addFollowCraftPhasePrice(data) {
    return request.post('/end/ola_backend/follow_craft_phase_price/add', data)
  },
  /**
   * getFollowCraftPhasePriceInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getFollowCraftPhasePrice(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/follow_craft_phase_price/detail', data)
  },
  /**
   * deleteFollowCraftPhasePriceInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteFollowCraftPhasePrice(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/follow_craft_phase_price/delete', data)
  },
  
   //组客户
  /**
   * getCustomerOrderItem
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomerOrderItems(data) {
    return request.post('/end/ola_backend/customer_order_item/list', data)
  },
   /**
   * ModifyCustomerOrderItem
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyCustomerOrderItem(data) {
    return request.post('/end/ola_backend/customer_order_item/modify', data)
  },
  /**
   * addCustomerOrderItem
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addCustomerOrderItem(data) {
    return request.post('/end/ola_backend/customer_order_item/add', data)
  },
  /**
   * getCustomerOrderItemInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomerOrderItem(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/customer_order_item/detail', data)
  },
  /**
   * deleteCustomerOrderItemInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteCustomerOrderItem(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/customer_order_item/delete', data)
  },

   //组客户
  /**
   * getCustomerOrderItemCraftDetail
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomerOrderItemCraftDetails(data) {
    return request.post('/end/ola_backend/customer_order_item_craft_detail/list', data)
  },
   /**
   * ModifyCustomerOrderItemCraftDetail
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyCustomerOrderItemCraftDetail(data) {
    return request.post('/end/ola_backend/customer_order_item_craft_detail/modify', data)
  },
  /**
   * addCustomerOrderItemCraftDetail
   * @description 更新
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addCustomerOrderItemCraftDetail(data) {
    return request.post('/end/ola_backend/customer_order_item_craft_detail/add', data)
  },
  /**
   * getCustomerOrderItemCraftDetailInfo
   * @description 获取详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomerOrderItemCraftDetail(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/customer_order_item_craft_detail/detail', data)
  },
  /**
   * deleteCustomerOrderItemCraftDetailInfo
   * @description 删除详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteCustomerOrderItemCraftDetail(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/customer_order_item_craft_detail/delete', data)
  },
  //客户订单
  /**
   * getUserCustomer
   * @description 管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCustomerOrders(data) {
    return request.post('/end/ola_backend/customer_orders/list', data)
  },
  getCustomerOrder(id) {
    let data = {id:id}
    return request.post('/end/ola_backend/customer_orders/detail', data)
  },
}