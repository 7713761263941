import * as request from '@/utils/request'

export default {
  /**
   * @description 获取角色列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
  getRolesList(data) {
    return request.post('/server/general/roles/manager/list',data)
  },
  /**
   * @description 新增角色
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addRoles(data) {
    return request.post('/server/general/roles/manager/add',data)
  },
  /**
   * @description 保存权限
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  modifyRoles(data) {
    return request.post('/server/general/roles/manager/modify',data)
  },
  /**
   * @description 根据id获取单条信息，编辑使用
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getRolesInfo(id) {
    let data = {id:id}
    return request.post('/server/general/roles/manager/detail',data)
  },
  /**
   * @description 根据id删除单条信息
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  deleteRoles(id) {
    let data = {id:id}
    return request.post('/server/general/roles/manager/delete',data)
  },
  /**
   * @description 角色权限绑定，获取信息
   * @author 春天在哪里
   * @date 2019-01-26
   * @param {*} id 角色id
   * @returns 
   */

  /**
   * @description 获取角色列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
  getSupplierList(data) {
    return request.post('/server/supplier/roles/list',data)
  },
  /**
   * @description 新增角色
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addSupplier(data) {
    return request.post('/server/supplier/roles/add',data)
  },
  /**
   * @description 保存权限
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  modifySupplier(data) {
    return request.post('/server/supplier/roles/modify',data)
  },
  /**
   * @description 根据id获取单条信息，编辑使用
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getSupplierInfo(id) {
    let data = {id:id}
    return request.post('/server/supplier/roles/detail',data)
  },
  /**
   * @description 根据id删除单条信息
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  deleteSupplier(id) {
    let data = {id:id}
    return request.post('/server/supplier/roles/delete',data)
  },
  /**
   * @description 角色权限绑定，获取信息
   * @author 春天在哪里
   * @date 2019-01-26
   * @param {*} id 角色id
   * @returns 
   */










  getRolesPermissions(id) {
    let data = {id:id}
    return request.post('/server/roles/get_permissions',data)
  },
  /**
   * @description 保存角色对应的权限
   * @author 春天在哪里
   * @date 2019-01-26
   * @param {*} data
   * @returns 
   */
  saveRolesPermissions(data) {
    return request.post('/server/roles/save_permissions',data)
  },
   /**
   * @description 获取管理员角色用户列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
  getRolesAdmin(data) {
    return request.post('/server/roles/user/list',data)
  },
     /**
   * @description 获取员工角色用户列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
    getRolesEmployee(data) {
      return request.post('/server/roles/get_employees',data)
    },
  /**
   * @description 用户组添加成员，用户搜索，qs请求参数
   * @author 春天在哪里
   * @date 2019-01-31
   * @param {*} qs 请求参数
   * @param {*} rId 角色id
   * @returns 
   */
  rolesSearchUser(qs,rId){
    //let data = {search:qs,role_id:rId}
    let data = {query:qs,role_id:rId}
   // return request.post('/server/roles/search_user',data)
    return request.post('/server/user/search',data)
  },
  /**
   * @description 用户组添加成员，用户搜索，qs请求参数
   * @author 春天在哪里
   * @date 2019-01-31
   * @param {*} qs 请求参数
   * @param {*} rId 角色id
   * @returns 
   */
  rolesSearchAdmin(qs){
    //let data = {search:qs,role_id:rId}
    let data = {query:qs}
   // return request.post('/server/roles/search_user',data)
    return request.post('/server/admin/search',data)
  },
  /**
   * @description 用户组添加成员，用户搜索，qs请求参数
   * @author 春天在哪里
   * @date 2019-01-31
   * @param {*} qs 请求参数
   * @param {*} rId 角色id
   * @returns 
   */
   rolesSearchEmployee(qs,rId){
    //let data = {search:qs,role_id:rId}
    let data = {query:qs,role_id:rId}
   // return request.post('/server/roles/search_user',data)
    return request.post('/server/employee/search',data)
  },
  /**
   * @description 为角色添加用户
   * @author 春天在哪里
   * @date 2019-01-31
   * @param {*} data
   * @returns 
   */
  saveRolesUser(data){
    return request.post('/server/roles/save_user',data)
  },
  /**
   * @description 为角色移除用户
   * @author 春天在哪里
   * @date 2019-01-31
   * @param {*} data
   * @returns 
   */
  removeRolesUser(data) {
    return request.post('/server/roles/remove_user',data)
  }
}