import * as request from '@/utils/request'
export default {
  /**
   * @description 提交登录信息，进行登录
   * @author 春天在哪里
   * @date 2019-02-12
   * @returns 
   */
  async  Login(data) { 
    return request.post('/server/user/login',data)
  },

   /**
   * @description 提交登录信息，查询个人信息
   * @author 春天在哪里
   * @date 2019-02-12
   * @returns 
   */
    userProfile() {
      return request.post('/server/admin/profile')
    },
     /**
   * @description 提交登录信息，进行新增或者修改
   * @author 春天在哪里
   * @date 2019-02-12
   * @returns 
   */
    storeProfile(data) {
        return request.post('/server/admin/modifyself',data)
    }, 
  /**
   * @description 提交退出登录
   * @author 春天在哪里
   * @date 2021-04-28
   * @returns 
   */
  submitLogout() {
    return request.post('/server/user/logout')
  },
}