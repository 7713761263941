import * as request from '@/utils/request'

export default {
  /**
   * @description 获取权限列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
  getPermissionsList(data) {
    return request.post('/server/permissions/list',data)
  },
    /**
   * @description 获取用户权限list，用于页面按钮相关的控制
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
  getUserPermissionsList(data) {
    return request.post('/server/user/permissions/list',data)
  },

  /**
   * @description 保存权限，新建、编辑的保存都走此方法，却别是有没有主键id
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addPermissions(data) {
    return request.post('/server/general/permission/common/add',data)
  },
  /**
   * @description 保存权限，新建、编辑的保存都走此方法，却别是有没有主键id
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  modifyPermissions(data) {
    return request.post('/server/general/permission/common/modify',data)
  },
  /**
   * @description 根据id获取单条信息，编辑使用
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getPermissionsInfo(id) {
    let data = {id:id}
    return request.post('/server/general/permission/common/detail',data)
  },
  /**
   * @description 根据id删除单条信息
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  deletePermissions(id) {
    let data = {id:id}
    return request.post('/server/general/permission/common/delete',data)
  },
  /**
   * @description 相同层级权限排序
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  orderPermissions(ids) {
    let data = {ids:ids}
    return request.post('/server/permissions/order',data)
  }
}