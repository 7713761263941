import * as request from '@/utils/request'

export default {
  /**
   * @description 获取菜单列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
  getMenuList(data) {
    return request.post('/server/menu/list',data)
  },
  /**
   * @description 获取权限列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
  getMenuPermissionsList() {
    return request.post('/server/menu/permissions_list')
  },
  /**
   * @description 保存菜单，新建、编辑的保存都走此方法，却别是有没有主键id
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addMenu(data) {
    return request.post('/server/general/menu/common/add',data)
  },
   /**
   * @description 保存菜单，新建、编辑的保存都走此方法，却别是有没有主键id
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyMenu(data) {
    return request.post('/server/general/menu/common/modify',data)
  },
  /**
   * @description 根据id获取单条信息，编辑使用
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getMenuInfo(id) {
    let data = {id:id}
    return request.post('/server/general/menu/common/detail',data)
  },
  /**
   * @description 根据id删除单条信息
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  deleteMenu(id) {
    let data = {id:id}
    return request.post('/server/general/menu/common/delete',data)
  },
  /**
   * @description 相同层级菜单排序
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  orderMenu(ids) {
    let data = {ids:ids}
    return request.post('/server/menu/order',data)
  },

}