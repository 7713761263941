import * as request from '@/utils/request'

export default {

  getAdminList(data) {
    return request.post('/server/general/user/common/list',data)
  },
   /**
   * @description 获取加盟用户列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @returns promise
   */
    getJionUserList(data) {
      return request.post('/server/user/jion',data)
    },
  /**
   * @description 保存权限，新建、编辑的保存都走此方法，却别是有没有主键id
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addUser(data) {
    let role_id=parseInt(data.role_id) 
    console.log(role_id)
    delete data['role_id']
    let role_user={role_id:role_id}
    let senddata = {
      role_user:role_user,
      item:data
    }
    return request.post('/server/roles/user/add',senddata)
  },
   /**
   * @description 保存权限，新建、编辑的保存都走此方法，却别是有没有主键id
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   modifyUser(data) {
      return request.post('/server/general/user/common/modify',data)
    },
  /**
   * @description 根据id获取单条信息，编辑使用
   * @author 春天在哪里
   * @date 2019-01-28
   */
  getUser(id) {
      let data = {id:id}
      return request.post('/server/general/user/common/detail',data)
    },
   /**
   * @description 根据id获取单条信息，编辑使用
   * @author 春天在哪里
   * @date 2019-01-28
   */
  getAdminInfo(id) {
      let data = {id:id}
      return request.post('/server/user/detail',data)
    },
  /**
   * @description 根据id删除单条信息
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  deleteUser(id) {
    let data = {id:id}
    return request.post('/server/user/delete',data)
  }, 
  /**
   * @description 根据id删除单条信息
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   deleteAdmin(id) {
    let data = {id:id}
    return request.post('/server/admin/delete',data)
  }, 
  /**
  * @description 用户绑定用户组，获取信息
  * @author 春天在哪里
  * @date 2019-01-26
  * @returns 
  */
 getUserRoles(data) {
   return request.post('/server/roles/list',data)
 }
}