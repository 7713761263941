import * as request from '@/utils/request'

export default {
  /**
   * getCategoryList
   * @description 分类管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCategoryList(data) {
    return request.post('/server/category/list', data)
  },
   /**
   * getCategoryList
   * @description 分类管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
    getCategoryPNameList(data) {
      return request.post('/server/category/pname/list', data)
    },
  /**
   * getCategoryList
   * @description 分类管理列表
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
   getCategoryNodes(data) {
    return request.post('/server/withitem/category_nodes/node', data)
  },
  /**
   * addCategory
   * @description 保存分类
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  addCategory(data) {
    return request.post('/server/general/category/common/add', data)
  },
  /**
   * modifyCategory
   * @description 保存分类
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  modifyCategory(data) {
    return request.post('/server/general/category/common/modify', data)
  },
  /**
   * getCategoryInfo
   * @description 获取分类详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} data
   * @returns
   */
  getCategoryInfo(id) {
    let data = {id:id}
    return request.post('/server/general/category/common/detail', data)
  },
  /**
   * deleteCategoryInfo
   * @description 删除分类详情
   * @author 春天在哪里
   * @date 2023-03-13
   * @param {*} id
   * @returns
   */
  deleteCategoryInfo(id) {
    let data = {id:id}
    return request.post('/server/general/category/common/delete', data)
  },
  /**
   * @description 分类排序
   * @author 春天在哪里
   * @date 2023-03-07
   * @param {*} ids
   * @returns 
   */
  orderCategory(ids) {
    let data = {ids:ids}
    return request.post('/server/category/order',data)
  },
}