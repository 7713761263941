import * as request from '@/utils/request'

export default {
  /**
   * getStatisticsFlowData
   * @description 流量统计
   * @author 春天在哪里
   * @date 2019-12-19
   * @returns
   */
  getStatisticsFlowData(data) {
    return request.post('/server/statistics/flow', data)
  },
  /**
   * getStatisticsRegionData
   * @description 地域统计
   * @author 春天在哪里
   * @date 2019-12-19
   * @returns
   */
  getStatisticsRegionData(data) {
    return request.post('/server/statistics/region', data)
  },
}
