import * as request from '@/utils/request'

export default {
  /**
   * @description 获取系统日志列表
   * @author 春天在哪里
   * @date 2020-02-16
   * @param {*} data
   * @returns 
   */
  getSysLogList(data) {
    return request.post('/server/general/system_logs/common/list', data)
  }
}